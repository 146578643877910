import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiButton } from '@elastic/eui';
import NavButtons from 'components/Downloads/Outdoor_Cameras/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9008 Full HD Downloads",
  "path": "/Downloads/Outdoor_Cameras/IN-9008_HD/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Firmware, WebUI and Documentation Downloads",
  "image": "./P_SearchThumb_IN-9008HD_Downloads.png",
  "social": "/images/Search/P_SearchThumb_IN-9008HD_Downloads.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Downloads_white.webp",
  "chapter": "Downloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Download Area - IN-9008 Full HD' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Software Downloads and Usermanuals for your INSTAR Products' image='/images/Search/P_SearchThumb_IN-9008HD_Downloads.png' twitter='/images/Search/P_SearchThumb_IN-9008HD_Downloads.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Downloads/Aussenkameras/IN-9008_HD/' locationFR='/fr/Downloads/Outdoor_Cameras/IN-9008_HD/' crumbLabel="IN-9008" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloads`}</h1>
    <h2 {...{
      "id": "outdoor-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outdoor-cameras",
        "aria-label": "outdoor cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outdoor Cameras`}</h2>
    <h2 {...{
      "id": "in-9008-full-hd-downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-9008-full-hd-downloads",
        "aria-label": "in 9008 full hd downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9008 Full HD Downloads`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#software-installation-wizard"
        }}>{`Software Installation Wizard`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instructions"
        }}>{`Instructions`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#quick-installation"
            }}>{`Quick Installation`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#camera-software-updates"
        }}>{`Camera software updates`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#firmware"
            }}>{`Firmware`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#download"
                }}>{`Download`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "software-installation-wizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#software-installation-wizard",
        "aria-label": "software installation wizard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software Installation Wizard`}</h2>
    <p>{`Every INSTAR product comes with an installation CD. The CD contains an installation assistant that guides you through the installation of your new INSTAR product. The installation wizard can also be called directly by clicking on the link below and opening the CD online. The wizard contains all the information and current download links you need for the installation.`}</p>
    <p>{`The software CD includes an HTML setup wizard that guides you through the required settings and displays the current download links for your INSTAR product.`}</p>
    <p><strong parentName="p">{`Quick Installation Wizard`}</strong>{`: The wizard guides you through the most important steps to get your INSTAR product up and running.`}</p>
    <p><strong parentName="p">{`Software Installation`}</strong>{`: You will find download links if your INSTAR product requires additional software. You are guided through all steps after you have completed the download and the installation.`}</p>
    <p><strong parentName="p">{`Firmware Updates`}</strong>{`: INSTAR provides firmware and WebUI updates for its products at regular intervals. Below you will find the latest versions of the firmware and WebUI which you can upload to your INSTAR camera.`}</p>
    <OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink"><EuiButton fill mdxType="EuiButton">Start Installation</EuiButton></OutboundLink>
    <h2 {...{
      "id": "instructions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instructions",
        "aria-label": "instructions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Instructions`}</h2>
    <h3 {...{
      "id": "quick-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#quick-installation",
        "aria-label": "quick installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quick Installation`}</h3>
    <Link to="https://wiki.instar.com/dl/IN-9008HD/QuickInstallation-1080p-IN-9008HD.pdf" target="_blank" rel="noopener noreferrer" mdxType="Link"><EuiButton fill mdxType="EuiButton">Quick Start Guide</EuiButton></Link>
    <h2 {...{
      "id": "camera-software-updates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-software-updates",
        "aria-label": "camera software updates permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera software updates`}</h2>
    <h3 {...{
      "id": "firmware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#firmware",
        "aria-label": "firmware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Firmware`}</h3>
    <p>{`You will always find here the latest version and all previous versions of the firmware for your INSTAR Camera. If you plan to update the WebUI, make sure that you have installed the latest version of the firmware. In most cases, the new features in the web interface (WebUI) also require the latest firmware. For information on how to update the firmware please look `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/WebUI_And_Firmware_Upgrade/"
      }}>{`Here`}</a>{`.`}</p>
    <p><strong parentName="p">{`Important`}</strong>{`: Unzip the * .ZIP file after downloading. Please note that you must first install the latest firmware and then the WebUI.`}</p>
    <h4 {...{
      "id": "download",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#download",
        "aria-label": "download permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.48_WebUI_3.3.(372)_update.zip"
          }}>{`FW 4.1.2.48 WebUI 3.3 (372)`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.48_WebUI_3.3.%28372%29_update.zip"
          }}>{`PoE FW 4.1.2.48 WebUI 3.3 (372)`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`TLS certificate renewed`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.48_WebUI_3.3.%28370%29_update.zip"
          }}>{`FW 4.1.2.48 WebUI 3.3`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.48_WebUI_3.3.%28370%29_update.zip"
          }}>{`PoE FW 4.1.2.48 WebUI 3.3`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Elastic Search replaces the previous INSTAR Wiki Search`}</li>
          <li parentName="ul">{`The MQTT alarm server status is reset automatically.`}</li>
          <li parentName="ul">{`A problem with the FritzBox was solved, where one was pointed to a new unknown device.`}</li>
          <li parentName="ul">{`The HTTPS certificate was updated`}</li>
          <li parentName="ul">{`The WebUI is no longer loaded in the iFrame`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "hhttps://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.48_WebUI_3.2.%28366%29_update.zip"
          }}>{`FW 4.1.2.48 WebUI 3.2`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.48_WebUI_3.2.%28366%29_update.zip"
          }}>{`PoE FW 4.1.2.48 WebUI 3.2`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Support of the Apple Homekit service`}</li>
          <li parentName="ul">{`Enable / disable Cloud License Plate or Face Capture via the Cloud menu of the camera webUI. Note: License plate or face capture must be present in your Cloud storage plan.`}</li>
          <li parentName="ul">{`The text-heavy setup wizard has been cleaned up and updated.`}</li>
          <li parentName="ul">{`Both the backup alarm schedule and the regular alarm schedule are now switchable via MQTT.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.48_WebUI_3.2.%28364%29_update.zip"
          }}>{`FW 4.1.2.48 WebUI 3.2`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.48_WebUI_3.2.%28363%29_update.zip"
          }}>{`PoE FW 4.1.2.48 WebUI 3.2`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Support of the Apple Homekit service`}</li>
          <li parentName="ul">{`Enable / disable Cloud License Plate or Face Capture via the Cloud menu of the camera webUI. Note: License plate or face capture must be present in your Cloud storage plan.`}</li>
          <li parentName="ul">{`The text-heavy setup wizard has been cleaned up and updated.`}</li>
          <li parentName="ul">{`Both the backup alarm schedule and the regular alarm schedule are now switchable via MQTT.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.48_WebUI_3.1.(350)_update.zip"
          }}>{`FW 4.1.2.48 WebUI 3.1`}</a>{` **`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.48_WebUI_3.1.(350)_update.zip"
          }}>{`PoE FW 4.1.2.48 WebUI 3.1`}</a>{` **`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Alexa Support - The INSTAR Cloud Skill, which allows you to view live video from your camera as well as recordings in the cloud, is now available.`}</li>
          <li parentName="ul">{`IFTTT Support - On the IFTTT platform we provide our service called INSTAR. The INSTAR applets offer you the possibility to control some settings of your INSTAR camera or INSTAR Cloud with IFTTT.`}</li>
          <li parentName="ul">{`A free INSTAR Cloud account is required to use Alexa and IFTTT. If you sign up now, you can also test the chargeable range of functions, such as the storage of alarm videos, for 30 days free of charge.`}</li>
          <li parentName="ul">{`With Alexa and IFTTT, the new menu category Smarthome was introduced. The alarm server and MQTT settings can now be found under Smarthome.`}</li>
          <li parentName="ul">{`MQTT topic prefix and client ID definable`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.48_WebUI_3.0.(328)_update.zip"
          }}>{`FW 4.1.2.48 WebUI 3.0`}</a>{` **`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.48_WebUI_3.0.(328)_update.zip"
          }}>{`PoE FW 4.1.2.48 WebUI 3.0`}</a>{` **`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Audio support for the Safari Browser (HTML5 video stream)`}</li>
          <li parentName="ul">{`Fullscreen view for HTML5 and MJPEG streams by double clicking or quick control button`}</li>
          <li parentName="ul">{`HTML5 audio stream can be muted with quick control button`}</li>
          <li parentName="ul">{`Timed IR control has been linked with the IR-Cut mode`}</li>
          <li parentName="ul">{`Timer plan for WDR mode is now available`}</li>
          <li parentName="ul">{`Timed settings now have priority and will be applied if the current time matches`}</li>
          <li parentName="ul">{`The SD card viewer has been reworked. Multiple files can now be downloaded at once.`}</li>
          <li parentName="ul">{`Alarm menu has been reorganized`}</li>
          <li parentName="ul">{`You can now change the recording resolution in the alarm menu`}</li>
          <li parentName="ul">{`Going forward, notification Pop-ups are resetted after each update`}</li>
          <li parentName="ul">{`General bug fixes and improvementsdern`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.48_WebUI_2.6.(320)_update.zip"
          }}>{`FW 4.1.2.48 WebUI 2.6`}</a>{` **`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.48_WebUI_2.6.(320)_update.zip"
          }}>{`PoE FW 4.1.2.48 WebUI 2.6`}</a>{` **`}</p>
        <ul parentName="li">
          <li parentName="ul">{`HTML5 Video-Stream für den neuen Edge (Chrome), Google Chrome, Mozilla Firefox und Safari Browser`}</li>
          <li parentName="ul">{`Das Menü für die Video-Einstellungen wurde überarbeitet`}</li>
          <li parentName="ul">{`Das SSL-Zertifikat für den HTTPS Zugriff und den MQTT-Dienst wurde aktualisiert.`}</li>
          <li parentName="ul">{`Falls das integrierte Zertifkat verwendet wird, benötigen angebundene MQTT-Klienten das neue Klientenzertifikat des MQTT-Broker.`}</li>
          <li parentName="ul">{`Allgemeine Bugfixes und Verbesserungen`}</li>
          <li parentName="ul">{`Allgemeine Bugfixes und Verbesserungen`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.47_WebUI_2.5.(308)_update.zip"
          }}>{`FW 4.1.2.47 WebUI 2.5`}</a>{` **`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.47_WebUI_2.5.(308)_update.zip"
          }}>{`PoE FW 4.1.2.47 WebUI 2.5`}</a>{` **`}</p>
        <ul parentName="li">
          <li parentName="ul">{`PTZ functionality completely revised IN-9010FullHD / IN-9020FullHD`}</li>
          <li parentName="ul">{`It is recommended to perform a PTZ reset! IN-9010FullHD / IN-9020FullHD`}</li>
          <li parentName="ul">{`Alarm suppression during PTZ available IN-9010FullHD / IN-9020FullHD`}</li>
          <li parentName="ul">{`PTZ reset button available in PTZ menu`}</li>
          <li parentName="ul">{`Simplified Cloud Integration`}</li>
          <li parentName="ul">{`From now on you have to enter the login data (email address and cloud password) of your cloud account instead of the camera cloud data as before.`}</li>
          <li parentName="ul">{`Extended alarm server functionalities`}</li>
          <li parentName="ul">{`Alarm activation/deactivation via DeepL Access button`}</li>
          <li parentName="ul">{`Changing the alarm video length no longer causes a restart`}</li>
          <li parentName="ul">{`General bugfixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.47_WebUI_2.5.(288)_update.zip"
          }}>{`FW 4.1.2.47 WebUI 2.5`}</a>{` `}{`[SHA265: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`39f56b0b9ff81a331c0a9074451e0038ea1cf39b9882233890df0d8945fdca0c`}</code>{`]`}{` **`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.47_WebUI_2.5.(288)_update.zip"
          }}>{`PoE FW 4.1.2.47 WebUI 2.5 *`}</a>{` `}{`[SHA265: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`aaf8c8f84061a399de123e68b54a6ad34876fb984bef7c607f2685c8da0e8784`}</code>{`]`}{` **`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Native smarthome support with the INSTAR MQTT Broker / Client (ioBroker, OpenHAB, Home Assistant, FHEM, Node-RED and many more...)`}</li>
          <li parentName="ul">{`Set a preferred BSSID in the WiFi configuration`}</li>
          <li parentName="ul">{`A problem with the RTSP stream with the Synology Surveillance Station has been fixed`}</li>
          <li parentName="ul">{`Cool-down interval between requests to the alarm server and  push notification is now configurable`}</li>
          <li parentName="ul">{`Test function for alarm server and push notification now available`}</li>
          <li parentName="ul">{`Custom schedules can be saved as presets`}</li>
          <li parentName="ul">{`WebUI can be added to the home screen of your smartphone (PWA)`}</li>
          <li parentName="ul">{`General bug fixes and layout changes`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.46_WebUI_2.4.(262)_update.zip"
          }}>{`FW 4.1.2.46 WebUI 2.4`}</a>{` `}{`[SHA265: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`94f4d21cc8be9f7605fc60c827c7eb4b2c6922a954bf106a650e18363a1172ec`}</code>{`]`}{` **`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.46_WebUI_2.4.(262)_update.zip"
          }}>{`PoE FW 4.1.2.46 WebUI 2.4 *`}</a>{` `}{`[SHA265: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`657e1cc5a93bddd4631ec26e364a0ee7d8df5d20f5385f110cb1613cfa525cb0`}</code>{`]`}{` **`}</p>
        <ul parentName="li">
          <li parentName="ul">{`PTZ functionalities have been reworked `}{`[IN-9010FullHD / IN-9020FullHD]`}</li>
          <li parentName="ul">{`A PTZ reset is strongly recommended! `}{`[IN-9010FullHD / IN-9020FullHD]`}</li>
          <li parentName="ul">{`Alarm prevention during PTZ available `}{`[IN-9010FullHD / IN-9020FullHD]`}</li>
          <li parentName="ul">{`PTZ reset button can be found in the PTZ settings`}</li>
          <li parentName="ul">{`Simplified cloud integration`}</li>
          <li parentName="ul">{`From now on it is required to use your Cloud Login data (email address & cloud password) instead of the individual cloud camera data.`}</li>
          <li parentName="ul">{`Extended alarm server functions`}</li>
          <li parentName="ul">{`Arming and disarming the alarm via quick access button`}</li>
          <li parentName="ul">{`Changing the recording length doesn't require a reboot anymore`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.43_WebUI_2.3.(242)_update.zip"
          }}>{`FW 4.1.2.43 WebUI 2.3`}</a>{` `}{`[SHA265: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`4545da46ee167033c9ba73d56d2655c8e5c52ebaabbea17fbe8f06c92e13388a`}</code>{`]`}{` **`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.43_WebUI_2.3.(242)_update.zip"
          }}>{`PoE FW 4.1.2.43 WebUI 2.3 *`}</a>{` `}{`[SHA265: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`8106503113e4e1f377a33048a1015948a84e6c872add2188fbf1d16b6b051073`}</code>{`]`}{` **`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Support of custom SSL certificates for HTTPS connection with a custom DDNS address`}</li>
          <li parentName="ul">{`Simple 1-Click update method`}</li>
          <li parentName="ul">{`Offline update with traditional PKG file is optionally available`}</li>
          <li parentName="ul">{`Alarm recordings up to 60 seconds`}</li>
          <li parentName="ul">{`Initial network integration via direct WiFi connection - This feature is available for WiFi cameras during initial setup or after a factory reset. After the network integration, the camera’s hotspot will be permanently disabled.`}</li>
          <li parentName="ul">{`An issue with hidden WiFi SSIDs has been solved`}</li>
          <li parentName="ul">{`IR-CUT Filter settings available`}</li>
          <li parentName="ul">{`Weather Widget has been removed * 1`}</li>
          <li parentName="ul">{`Factory reset and WPS activation via SD card has been simplified * 2`}</li>
          <li parentName="ul">{`General bugfixes and layout changes`}</li>
        </ul>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`1 The weather service of Yahoo Weather has been discontinued in its previous form. This feature will be reintroduced to the cameras WebUI at a later point. `}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`2 Reset and WPS via SD card can now be triggered by a file with the text file extension .txt - For a reset, simply create an empty file in the root folder of you sd card with the name “reset” or “reset.txt” - For WPS activation the file must be named “wps” or “wps.txt”`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.42_WebUI_2.2.(195)_update.zip"
          }}>{`FW 4.1.2.42 WebUI 2.2`}</a>{` `}{`[SHA265: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`8640b95076201d8a9cf495ab39bb9e3e6933ff91080890bae757dfe0f0870587`}</code>{`]`}{` **`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.42_WebUI_2.2.(195)_update.zip"
          }}>{`PoE FW 4.1.2.42 WebUI 2.2 *`}</a>{` `}{`[SHA265: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`5de6a77ccd7d4e8cb6e71c668c19b49406d9ac548186f4c228986aada0dc034c`}</code>{`]`}{` **`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Thresholds for day and night switching are now configurable`}</li>
          <li parentName="ul">{`Backup & Restore settings can be saved and re-imported at a later time`}</li>
          <li parentName="ul">{`General bugfixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.42_WebUI_2.1.(192)_update.zip"
          }}>{`FW 4.1.2.42 WebUI 2.1`}</a>{` `}{`[SHA265: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`272d89551df2b87aa47659a7520213284ecf72cb5120311e6de333cc54e5c16b`}</code>{`]`}{` **`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Alarm areas can be configured in groups triggered by different alarm schedules`}</li>
          <li parentName="ul">{`Automatic deletion of recordings (time-controlled ring storage)`}</li>
          <li parentName="ul">{`Recordings on the SD card can be sorted in the WebUI by name, date and size`}</li>
          <li parentName="ul">{`Image preview in the SD viewer can be controlled with the arrow keys`}</li>
          <li parentName="ul"><em parentName="li">{`WebUI`}</em>{` menu`}</li>
          <li parentName="ul">{`Night mode for the WebUI (can be activated in the `}<em parentName="li">{`WebUI`}</em>{` menu or by the o key)`}</li>
          <li parentName="ul">{`Third query command for the alarm server function available`}</li>
          <li parentName="ul">{`In the logbook, the IP address can now be recorded with the login - Can be activated under `}<em parentName="li">{`User Management`}</em></li>
          <li parentName="ul">{`Fixed a bug in the RTSP stream which only occurred when the guest account was disabled`}</li>
          <li parentName="ul">{`updated HTTPS certificate`}</li>
          <li parentName="ul">{`WLAN transmission up to 150M`}</li>
          <li parentName="ul">{`The camera name serves as the alias / sender name of the emails`}</li>
          <li parentName="ul">{`Polish language support`}</li>
          <li parentName="ul">{`Graphical adjustments in the WebUI`}</li>
          <li parentName="ul">{`WPS & Reset can be activated via SD card (in the case of a missing reset button, you can create the file `}<strong parentName="li">{`wps`}</strong>{` or `}<strong parentName="li">{`reset`}</strong>{` in the root directory of the SD card and then insert it into the camera)`}</li>
          <li parentName="ul">{`Important security updates`}</li>
          <li parentName="ul">{`KRACK vulnerability in WPA2 encrypted Wi-Fi connections closed`}</li>
          <li parentName="ul">{`General bugfixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.39_WebUI_1.4(170)_update.zip"
          }}>{`FW 4.1.2.39 WebUI 1.4`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008-PoE_FW_4.1.2.39_WebUI_1.4(170)_update.zip"
          }}>{`PoE FW 4.1.2.39 WebUI 1.4 *`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Integration of manual alarm triggering via CGI`}</li>
          <li parentName="ul">{`ONVIF time zone changed`}</li>
          <li parentName="ul">{`Improvement of the loading speed`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.37_WebUI_1.3.(167)_update.zip"
          }}>{`FW 4.1.2.37 WEBUI 1.3`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Fixed problem with IE11`}</li>
          <li parentName="ul">{`Fixed problem with layout and sending control commands on Google Chrome`}</li>
          <li parentName="ul">{`Fixed a small problem with P2P which could lead to a crash in the app`}</li>
          <li parentName="ul">{`Display problem of the camera names corrected`}</li>
          <li parentName="ul">{`Fixed problem of wrong time stamp when sending emails`}</li>
          <li parentName="ul">{`General bug fixes and improvements`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.36_WebUI_1.2.(160)_update.zip"
          }}>{`FW 4.1.2.36 WEBUI 1.2`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`A problem with Firefox 32bit was fixed temporarily`}</li>
          <li parentName="ul">{`Email validation extends to very long domain names`}</li>
          <li parentName="ul">{`Problem fixed when the first start of the web interface, if necessary no video was displayed`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.36_WebUI_1.1.(158)_update.zip"
          }}>{`FW 4.1.2.36 WEBUI 1.1`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Day 1 Patch`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://wiki.instar.com/dl/IN-9008HD/IN-9008_FW_4.1.2.36_WebUI_1.0(151)_update.pkg"
          }}>{`FW 4.1.2.36 WEBUI 1.0`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Release Candidate`}</li>
        </ul>
      </li>
    </ul>
    <div className="warningbox">
 (*) This version may only be installed on a 9008 Power-over-Ethernet and is not on a 9008 WLAN.
    </div>
    <p><em parentName="p">{`** `}<a parentName="em" {...{
          "href": "/en/Downloads/Security/"
        }}>{`What is a SHA256 Hash ?`}</a></em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      